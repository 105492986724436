const templatePath = ({ filter, slug }, suffix = null, hash = null) => {
  return `/${filter}/${slug}${suffix ? `/${suffix}` : ''}/${hash ? `#${hash}` : ''}`
}

const previewUrl = ({ previewUrl, timestamp, slug }) => {
  return `${previewUrl}${timestamp && ['vue-dashboard','react-dashboard'].includes(slug) ? '' : `?t=${timestamp}`}`
}

module.exports = {
  templatePath,
  previewUrl,
}
