import React from 'react'
import { graphql, Link } from 'gatsby'
// import filter from 'lodash/filter'
// import { pageLinks, useInfoPagesData } from '../hooks/infoPages'
import Seo from '../components/seo'
import SectionTitle from '../components/sectionTitle'
import SectionDefault from '../components/sectionDefault'
import Logo from '../components/logo'
import { templatePath } from '../helpers'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import TemplateButtons from '../components/templateButtons'
import SectionButtons from '../components/sectionButtons'

const Structure = ({
  data: {
    mdx: { frontmatter: item },
  },
}) => {
  return (
    <>
      <section className="max-w-4xl mx-auto my-6 px-6">
        <Link to={templatePath(item)}>&larr; Back to template page</Link>
      </section>
      <Seo title={`Structure - ${item.title}`} />
      <SectionTitle
        title={item.title}
        subtitle={item.subtitle}
        tags={item.tags}
        premium={!item.isFree}
      />
      {item.package && (
        <>
          <SectionTitle title={`package.json`} id="package" />
          <SectionDefault>
            <div className={`prose mx-auto`}>
              <MDXRenderer>{item.package.childMdx.body}</MDXRenderer>
            </div>
          </SectionDefault>
          <SectionButtons>
            <TemplateButtons
              links={[
                {
                  to: templatePath(item, null, 'package'),
                  label: 'Back to template page',
                  style: 'buttonBlackLarge',
                  back: true,
                },
              ]}
            />
          </SectionButtons>
        </>
      )}
      {item.structure && (
        <>
          <SectionTitle title={`File structure`} id="structure" />
          <SectionDefault>
            <div className={`prose mx-auto`}>
              <MDXRenderer>{item.structure.childMdx.body}</MDXRenderer>
            </div>
          </SectionDefault>
          <SectionButtons>
            <TemplateButtons
              links={[
                {
                  to: templatePath(item, null, 'package'),
                  label: 'Back to template page',
                  style: 'buttonBlackLarge',
                  back: true,
                },
              ]}
            />
          </SectionButtons>
        </>
      )}

      <section className="flex justify-center my-24 px-6">
        <Link to="/">
          <Logo />
        </Link>
      </section>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        filter
        slug
        title
        subtitle
        longTitle
        tags
        icons
        package {
          childMdx {
            body
          }
        }
        structure {
          childMdx {
            body
          }
        }
      }
    }
  }
`

export default Structure
