import * as React from 'react'
import { Link } from 'gatsby'
import styles from '../styles/styles'

const TemplateButtons = ({ links }) => {
  return (
    <div className="flex items-center justify-center">
      {links.map((link) => {
        if (link.to.indexOf('https://') === 0) {
          return (
            <a key={link.to} href={link.to} target="_blank" className={styles[link.style]}>
              {link.label}
            </a>
          )
        }

        return (
          <Link key={link.to} to={link.to} className={styles[link.style]}>
            {link.back && <span>&larr;&nbsp;</span>}
            {link.label}
          </Link>
        )
      })}
    </div>
  )
}

export default TemplateButtons
