const buttonBase =
  'leading-tight inline-flex items-center justify-center border-2 transition-colors focus:ring outline-none'
const buttonBaseStyle = `${buttonBase} mr-3 last:mr-0 rounded-full ring-blue-700`
const buttonDefault =
  'bg-blue-600 border-blue-600 text-white hover:bg-blue-700 hover:border-blue-700'
const buttonBlack = 'bg-gray-900 border-black text-white hover:bg-gray-800'
const buttonBlackOutline = 'text-gray-800 border-black hover:bg-gray-900 hover:text-white'
const buttonOutline =
  'text-blue-600 border-blue-600 hover:bg-blue-700 hover:border-blue-700 hover:text-white'
// const buttonSizeSmall = 'py-1.5 px-6 text-sm'
const buttonSizeNormal = 'py-3 px-6'
const buttonSizeNormalSpaced = 'py-3 px-12'
const buttonSizeLarge = 'py-3 px-6 text-lg'
const buttonSizeXl = 'py-3 px-6 text-xl'

const styles = {
  input: 'border border-gray-900 h-12 px-3 rounded-xl focus:ring ring-blue-700',
  buttonDefault: [buttonBaseStyle, buttonDefault, buttonSizeNormal].join(' '),
  buttonDefaultSpaced: [buttonBaseStyle, buttonDefault, buttonSizeNormalSpaced].join(' '),
  buttonOutline: [buttonBaseStyle, buttonOutline, buttonSizeNormal].join(' '),
  buttonDefaultLarge: [buttonBaseStyle, buttonDefault, buttonSizeLarge].join(' '),
  buttonOutlineLarge: [buttonBaseStyle, buttonOutline, buttonSizeLarge].join(' '),
  buttonDefaultXl: [buttonBaseStyle, buttonDefault, buttonSizeXl].join(' '),
  buttonOutlineXl: [buttonBaseStyle, buttonOutline, buttonSizeXl].join(' '),
  // buttonBlack: [buttonBaseStyle, buttonBaseMargin, buttonBlack, buttonSizeNormal].join(' '),
  // buttonBlackJoined: [buttonBase, buttonBlack, buttonSizeSmall].join(' '),
  // buttonBlackOutlineJoined: [buttonBase, buttonBlackOutline, buttonSizeSmall].join(' '),
  buttonBlackLarge: [buttonBaseStyle, buttonBlack, buttonSizeLarge].join(' '),
  buttonBlackOutlineLarge: [buttonBaseStyle, buttonBlackOutline, buttonSizeLarge].join(' '),
}

export default styles
